import styled from "styled-components"

import { CallAssistUpsellPopupButton } from "src/components/MonitoringPresets/CallAssistUpsellPopupButton"
import {
  TCallAssistUpsellContext,
  usePostCallAssistUpsellClicked,
} from "src/data/analytics/queries/CallAssistAnalyticsQueries"
import { useGetCallAssistActive } from "src/data/callAssist/queries/callAssistQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { InternalLink } from "src/ui/Link/InternalLink"
import { spacing } from "src/ui/spacing"

/** Button to open the Call Assist subscription dialog. */
export function CallAssistSubscribeCTA({
  hidden,
  label,
  context,
}: {
  hidden?: boolean
  label?: string
  context: TCallAssistUpsellContext
}) {
  const { t, langKeys } = useTranslate()

  const { callAssistIsActive, isInitialLoading } = useGetCallAssistActive()
  const postCallAssistUpsellClicked = usePostCallAssistUpsellClicked()

  function callTracking() {
    postCallAssistUpsellClicked.mutate({ context })
  }

  function handleCTAClick() {
    callTracking()
  }

  if (hidden || callAssistIsActive || isInitialLoading) {
    return null
  }

  return (
    <Box>
      <InternalLink
        beforeNav={handleCTAClick}
        to={Routes.CallAssistActivate.location()}
      >
        {label ?? t(langKeys.event_log_call_assist_cta)}
      </InternalLink>

      <CallAssistUpsellPopupButton context={context} />
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  gap: ${spacing.XS};
`
