import styled from "styled-components"

import { subHours } from "date-fns"

import { CallAssistSubscribeCTA } from "src/components/EventLog/CallAssistSubscribeCTA"
import { LegacyEventIcon } from "src/components/EventLog/EventIcon"
import { UpgradeIconButton } from "src/components/FeatureBlockers/UpgradeIconButton"
import { CreateNoiseIncidentReportButton } from "src/components/Reports/CreateNoiseIncidentReportButton"
import {
  hasDeviceMetaData,
  isNoiseDetectedMetadata,
  THomeLogEvent,
} from "src/data/events/types/homeEventTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { TextButton } from "src/ui/Button/TextButton"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeCell({
  homeId,
  homeName,
}: {
  homeId: string
  homeName: string | undefined
}) {
  const { t, langKeys } = useTranslate()
  const home = t(langKeys.home)
  const fallbackCopy = t(langKeys.go_to_generic_text, { name: home })

  return (
    <div>
      <InternalLink to={Routes.Home.location(homeId)}>
        {homeName ?? fallbackCopy}
      </InternalLink>
    </div>
  )
}

function HomeLogFeedback({
  event,
  onSmokeFeedbackClicked,
}: {
  event: THomeLogEvent
  onSmokeFeedbackClicked: () => void
}) {
  const { t, langKeys } = useTranslate()

  if (event.event_type === "noise_quieted") {
    return (
      <CreateNoiseIncidentReportButton
        variant="minimal"
        label={t(langKeys.download_incident_report_button_action)}
        renderChild={({ featureAvailable }) =>
          !featureAvailable && <UpgradeIconButton context="incident_reports" />
        }
        prefillData={{
          homeId: event.home_id,
          date: subHours(new Date(event.event_time), 2),
          duration: 3,
        }}
      />
    )
  }
  if (event.event_type === "smoking_detected") {
    return (
      <>
        <TextButton onClick={onSmokeFeedbackClicked}>
          {t(langKeys.log_feedback_event_cigarette_detection)}
        </TextButton>
      </>
    )
  }

  if (
    event.event_type === "noise_detected" &&
    isNoiseDetectedMetadata(event.metadata?.event_specific_data) &&
    event.metadata?.event_specific_data.noise_count === 1
  ) {
    return <CallAssistSubscribeCTA context={"home_view_log"} />
  }

  return null
}

export function HomeLogBodyCell({
  event,
  children,
  onSmokeFeedbackClicked,
}: {
  event: THomeLogEvent
  children?: React.ReactNode
  onSmokeFeedbackClicked: (event: THomeLogEvent) => void
}) {
  return (
    <BodyBox>
      <EventIcon event={event} />

      <div>
        <MText variant="subtitle">{event.user_representation.title}</MText>
        <MText variant="bodyS" color="secondary">
          {event.user_representation.body}
        </MText>

        <HomeLogFeedback
          event={event}
          onSmokeFeedbackClicked={() => onSmokeFeedbackClicked(event)}
        />

        {children}
      </div>
    </BodyBox>
  )
}

const BodyBox = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: ${spacing.L};
  align-items: center;
`

function EventIcon({ event }: { event: THomeLogEvent }) {
  if (!event.user_representation.icon) {
    return <LegacyEventIcon eventType={event.event_type} />
  }
  return (
    <img
      src={event.user_representation.icon}
      alt={event.user_representation.title}
      height={40}
      width={40}
    />
  )
}

export function HomeLogDeviceCell({
  data,
}: {
  data: THomeLogEvent["metadata"]["event_specific_data"]
}) {
  const { t, langKeys } = useTranslate()
  const device = t(langKeys.sensor)
  const fallbackCopy = t(langKeys.go_to_generic_text, { name: device })

  if (!hasDeviceMetaData(data)) return <div></div>

  return (
    <div>
      <InternalLink to={Routes.SensorsWithDeviceId.location(data.device_id)}>
        {data.device_name ?? fallbackCopy}
      </InternalLink>
    </div>
  )
}
