export enum EventIconType {
  DEVICE_POWER_OFF = "device_power_off",
  DEVICE_INSTALL = "device_install",
  FIRST_HEARTBEAT = "first_heartbeat",
  SECOND_HEARTBEAT_SOUND_SUMMARY = "second_heartbeat_sound_summary",
  FIRST_MORNING = "first_morning",
  ALARM_DETECTION_TEST = "alarm_detection_test",
  INTRODUCTION_DAY_0_IMMEDIATE = "introduction_day_0_immediate",
  INTRODUCTION_DAY_1 = "introduction_day_1",
  INTRODUCTION_DAY_2_DEVICE_INSTALLED = "introduction_day_2_device_installed",
  INTRODUCTION_DAY_2_NO_DEVICE = "introduction_day_2_no_device",
  INTRODUCTION_DAY_4 = "introduction_day_4",
  INTRODUCTION_DAY_7 = "introduction_day_7",
  TEMPERATURE_HIGH = "temperature_high",
  TEMPERATURE_LOW = "temperature_low",
  TEMPERATURE_DROPPED_NORMAL = "temperature_dropped_normal",
  TEMPERATURE_RISEN_NORMAL = "temperature_risen_normal",
  TEMPERATURE_RAPID_RISE = "temperature_rapid_rise",
  HUMIDITY_HIGH = "humidity_high",
  HUMIDITY_LOW = "humidity_low",
  HUMIDITY_DROPPED_NORMAL = "humidity_dropped_normal",
  HUMIDITY_RISEN_NORMAL = "humidity_risen_normal",
  SHORT_BUTTON_PRESS = "short_button_press",
  ALARM_HEARD = "alarm_heard",
  SMOKE_DETECTED = "smoke_detected",
  DEVICE_ONLINE = "device_online",
  DEVICE_OFFLINE = "device_offline",
  TAMPER = "tamper",
  TAMPER_MOUNTED = "tamper_mounted",
  TAMPER_REMOVED = "tamper_removed",
  AVG_SOUND_HIGH = "avg_sound_high",
  PEAK_SOUND_HIGH = "peak_sound_high",
  SOUND_LEVEL_DROPPED_NORMAL = "sound_level_dropped_normal",
  SOUND_LEVEL_HIGH_DESPITE_WARNING = "sound_level_high_despite_warning",
  BATTERY_LOW = "battery_low",
  BATTERY_EMPTY = "battery_empty",
  CHARGER_CONNECTED = "charger_connected",
  CHARGER_DISCONNECTED = "charger_disconnected",
  BATTERY_CHARGING_COMPLETE = "battery_charging_complete",
  GLASSBREAK = "glassbreak",
  PIR_MOTION = "pir_motion",
  ALARM_GRACE_PERIOD_EXPIRED = "alarm_grace_period_expired",
  ALARM_MUTED_BUTTON_PRESS = "alarm_muted_button_press",
  ALARM_SILENCED = "alarm_silenced",
  ALARM_ON_AUTOMATICALLY = "alarm_on_automatically",
  ALARM_OFF_AUTOMATICALLY = "alarm_off_automatically",
  ALARM_NOT_AUTO_ON_ALL_DEVICES_OFFLINE = "alarm_not_auto_on_all_devices_offline",
  WIFI_RECONFIGURED = "wifi_reconfigured",
  USER_INVITED_ANOTHER_USER_TO_HOME = "user_invited_another_user_to_home",
  USER_RECEIVED_INVITATION_TO_OTHER_HOME = "user_received_invitation_to_other_home",
  INVITATION_ACCEPTED_TO_HOME_MEMBERS_VIEW = "invitation_accepted_to_home_members_view",
  INVITATION_ACCEPTED_TO_HOME_INVITEE_VIEW = "invitation_accepted_to_home_invitee_view",
  NON_EXISTING_USER_INVITED_TO_HOME = "non_existing_user_invited_to_home",
  USER_ARRIVED_HOME = "user_arrived_home",
  USER_LEFT_HOME = "user_left_home",
  MEMBER_ARRIVED_HOME = "member_arrived_home",
  MEMBER_LEFT_HOME = "member_left_home",
  TURN_ON_ALARM_REMINDER = "turn_on_alarm_reminder",
  TURN_OFF_ALARM_REMINDER = "turn_off_alarm_reminder",
  LAST_MEMBER_LEFT = "last_member_left",
  FIRST_MEMBER_ARRIVED = "first_member_arrived",
  SMOKE_DETECTOR_TEST_REMINDER = "smoke_detector_test_reminder",
  WEBHOOK_PING = "webhook_ping",
  ALMOST_FREEZING = "almost_freezing",
  BEDROOM_TOO_WARM = "bedroom_too_warm",
  AIR_TOO_DRY = "air_too_dry",
  AIR_TOO_HUMID = "air_too_humid",
  ROOM_TOO_WARM = "room_too_warm",
  RISK_OF_MOULD = "risk_of_mould",
  RISK_OF_MOULD_CLEARED = "risk_of_mould_cleared",
  DISTURBANCE_FIRST_NOTICE = "disturbance_first_notice",
  DISTURBANCE_SECOND_NOTICE = "disturbance_second_notice",
  DISTURBANCE_THIRD_NOTICE = "disturbance_third_notice",
  DISTURBANCE_ENDED = "disturbance_ended",
  DISTURBANCE_DISPATCHED_AUTOMATICALLY = "disturbance_dispatched_automatically",
  DISTURBANCE_DISPATCHED_MANUALLY = "disturbance_dispatched_manually",
  DISTURBANCE_LEVEL_4_AUTOMATICALLY = "disturbance_level_4_automatically",
  DISTURBANCE_LEVEL_4_MANUALLY = "disturbance_level_4_manually",
  DISTURBANCE_DISMISSED = "disturbance_dismissed",
  DISTURBANCE_SNOOZED = "disturbance_snoozed",
  DISTURBANCE_POTENTIAL_NOISE = "disturbance_potential_noise",
  SOUND_OF_INTEREST = "sound_of_interest",
  DOG_BARK = "dog_bark",
  SMOKING_DETECTION_SMOKING_DETECTED = "smoking_detection_smoking_detected",
  SMOKING_DETECTION_CALIBRATION_DONE = "smoking_detection_calibration_done",

  // MRS
  DISTURBANCE_FIRST_NOTICE_RESPONSE_SERVICE = "disturbance_first_notice_response_service",
  DISTURBANCE_SECOND_NOTICE_RESPONSE_SERVICE = "disturbance_second_notice_response_service",
  DISTURBANCE_THIRD_NOTICE_RESPONSE_SERVICE = "disturbance_third_notice_response_service",
  RESPONSE_SERVICE_DISPATCH_ARRIVED = "response_service_dispatch_arrived",
  RESPONSE_SERVICE_DISPATCH_IN_PROGRESS = "response_service_dispatch_in_progress",
  RESPONSE_SERVICE_DISPATCH_COMPLETED = "response_service_dispatch_completed",
  RESPONSE_SERVICE_DISPATCH_CANCELLED_BY_USER = "response_service_dispatch_cancelled_by_user",
  RESPONSE_SERVICE_DISPATCH_CANCELLED_BY_PROVIDER = "response_service_dispatch_cancelled_by_provider",
  RESPONSE_SERVICE_DISPATCH_CANCELLED_BY_MINUT_WHILE_IN_PROGRESS = "response_service_dispatch_cancelled_by_minut_while_in_progress",
  RESPONSE_SERVICE_DISPATCH_CANCELLED_BY_MINUT_WHILE_REQUESTED = "response_service_dispatch_cancelled_by_minut_while_requested",
  RESPONSE_SERVICE_DISPATCH_REQUESTED_MANUALLY = "response_service_dispatch_requested_manually",
  RESPONSE_SERVICE_DISPATCH_REQUESTED_AUTOMATICALLY = "response_service_dispatch_requested_automatically",

  // Call center
  CALLCENTER_GUEST_CALL = "callcenter_guest_call",
  CALLCENTER_GUEST_CALL_COMPLETED = "callcenter_guest_call_completed",
  CALLCENTER_GUEST_CALL_NO_ANSWER = "callcenter_guest_call_no_answer",

  // These events reflect when the number of wireless devices in a home go below or above the limit
  OCCUPANCY_SCANNED_DEVICE_LIMIT_EXCEEDED = "occupancy_scanned_device_limit_exceeded",
  OCCUPANCY_SCANNED_DEVICE_LIMIT_SUBCEEDED = "occupancy_scanned_device_limit_subceeded",

  // Sensor swap events to notify the user for the outcome of the procedure
  SENSOR_SWAP_SUCCEEDED = "sensor_swap_succeeded",
  SENSOR_SWAP_FAILED = "sensor_swap_failed",

  // Eureka
  FIRE_SMOKE_ALARM = "fire_smoke_alarm",
  FIRE_SMOKE_CLEARED = "fire_smoke_cleared",
  CO_ALARM = "co_alarm",
  CO_CLEARED = "co_cleared",
  FIRE_CO_SELF_TEST_SUCCESS = "fire_and_co_self_test_success",
  FIRE_CO_SELF_TEST_FAIL = "fire_and_co_self_test_fail",
}
