import styled from "styled-components"

import { EventIconType } from "src/data/events/types/eventTypes"
import { colorsLegacy, semanticEmergency } from "src/ui/colors"
import SmokingDetectionIcon from "src/ui/icons/cigarette-detection.svg"
import COAlarmIcon from "src/ui/icons/eventIcons/co-alarm.svg"
import DeviceOffIcon from "src/ui/icons/eventIcons/device-off.svg"
import ErrorLogIcon from "src/ui/icons/eventIcons/error-log.svg"
import AlarmHeardIcon from "src/ui/icons/eventIcons/event-alarm-heard.svg"
import AlarmOnIcon from "src/ui/icons/eventIcons/event-alarm-on.svg"
import BatteryEmptyIcon from "src/ui/icons/eventIcons/event-battery-empty.svg"
import BatteryLowIcon from "src/ui/icons/eventIcons/event-battery-low.svg"
import CallAssistIcon from "src/ui/icons/eventIcons/event-call-assist.svg"
import DefaultIcon from "src/ui/icons/eventIcons/event-default.svg"
import DeviceIcon from "src/ui/icons/eventIcons/event-device.svg"
import DeviceOfflineIcon from "src/ui/icons/eventIcons/event-device-offline.svg"
import DeviceOnlineIcon from "src/ui/icons/eventIcons/event-device-online.svg"
import DogBarkIcon from "src/ui/icons/eventIcons/event-dog-bark.svg"
import FreezingIcon from "src/ui/icons/eventIcons/event-freezing.svg"
import GlassBreakIcon from "src/ui/icons/eventIcons/event-glass-break.svg"
import HighCrowdRiskIcon from "src/ui/icons/eventIcons/event-high-crowd-risk.svg"
import HighTemperatureIcon from "src/ui/icons/eventIcons/event-high-temperature.svg"
import HomeIcon from "src/ui/icons/eventIcons/event-home.svg"
import HumidityIcon from "src/ui/icons/eventIcons/event-humidity.svg"
import MotionDetectedIcon from "src/ui/icons/eventIcons/event-motion-detected.svg"
import MouldIcon from "src/ui/icons/eventIcons/event-mould.svg"
import NoiseIcon from "src/ui/icons/eventIcons/event-noise.svg"
import SensorSwapFailedIcon from "src/ui/icons/eventIcons/event-sensor-swap-failed.svg"
import SensorSwapSuccessIcon from "src/ui/icons/eventIcons/event-sensor-swap-success.svg"
import TamperIcon from "src/ui/icons/eventIcons/event-tamper.svg"
import TemperatureIcon from "src/ui/icons/eventIcons/event-temperature-medium.svg"
import TemperatureRapidRiseIcon from "src/ui/icons/eventIcons/event-temperature-rapid-rise.svg"
import UserInviteIcon from "src/ui/icons/eventIcons/event-user-invite.svg"
import FireAlarmIcon from "src/ui/icons/eventIcons/fire-alarm.svg"
import OkLogIcon from "src/ui/icons/eventIcons/ok-log.svg"
import ResponseServiceIcon from "src/ui/icons/response-service.svg"

/*
The new home log has entirely replaced the legacy event log, except for one
area: When a user searches for events older than a certain cutoff date, legacy
events will be returned.

These legacy events don’t necessarily include an icon, so we have client side
logic + svgs for rendering icons for such events.

At some point, we should be able to remove all custom client side icon handling.

When doing so, we should also remove all related icons (unless they are used
elsewhere).
*/
export function LegacyEventIcon({ eventType }: { eventType: string }) {
  return (
    <IconBox>
      {eventIconMap[eventType as EventIconType] ?? <DefaultIcon />}
    </IconBox>
  )
}

const eventIconMap: { [key in EventIconType]: React.ReactNode } = {
  [EventIconType.DEVICE_ONLINE]: <DeviceOnlineIcon />,
  [EventIconType.DEVICE_OFFLINE]: <DeviceOfflineIcon />,
  [EventIconType.FIRST_HEARTBEAT]: <DeviceIcon />,
  [EventIconType.SECOND_HEARTBEAT_SOUND_SUMMARY]: <DeviceIcon />,
  [EventIconType.INTRODUCTION_DAY_0_IMMEDIATE]: <HomeIcon />,
  [EventIconType.TEMPERATURE_HIGH]: <HighTemperatureIcon />,
  [EventIconType.TEMPERATURE_LOW]: <TemperatureIcon />,
  [EventIconType.TEMPERATURE_DROPPED_NORMAL]: <TemperatureIcon />,
  [EventIconType.TEMPERATURE_RISEN_NORMAL]: <TemperatureIcon />,
  [EventIconType.TEMPERATURE_RAPID_RISE]: (
    <TemperatureRapidRiseIcon style={{ color: semanticEmergency }} />
  ),
  [EventIconType.HUMIDITY_HIGH]: <HumidityIcon />,
  [EventIconType.HUMIDITY_LOW]: <HumidityIcon />,
  [EventIconType.HUMIDITY_DROPPED_NORMAL]: <HumidityIcon />,
  [EventIconType.HUMIDITY_RISEN_NORMAL]: <HumidityIcon />,
  [EventIconType.SHORT_BUTTON_PRESS]: <DeviceIcon />,
  [EventIconType.ALARM_HEARD]: (
    <AlarmHeardIcon style={{ color: semanticEmergency }} />
  ),
  [EventIconType.AVG_SOUND_HIGH]: <NoiseIcon />,
  [EventIconType.PEAK_SOUND_HIGH]: <NoiseIcon />,
  [EventIconType.SOUND_LEVEL_DROPPED_NORMAL]: <NoiseIcon />,
  [EventIconType.SOUND_LEVEL_HIGH_DESPITE_WARNING]: <NoiseIcon />,
  [EventIconType.DISTURBANCE_FIRST_NOTICE]: <NoiseIcon />,
  [EventIconType.DISTURBANCE_SECOND_NOTICE]: <NoiseIcon />,
  [EventIconType.DISTURBANCE_THIRD_NOTICE]: <NoiseIcon />,
  [EventIconType.DISTURBANCE_FIRST_NOTICE_RESPONSE_SERVICE]: <NoiseIcon />,
  [EventIconType.DISTURBANCE_SECOND_NOTICE_RESPONSE_SERVICE]: <NoiseIcon />,
  [EventIconType.DISTURBANCE_THIRD_NOTICE_RESPONSE_SERVICE]: <NoiseIcon />,
  [EventIconType.DISTURBANCE_DISPATCHED_AUTOMATICALLY]: <NoiseIcon />,
  [EventIconType.DISTURBANCE_DISPATCHED_MANUALLY]: <NoiseIcon />,
  [EventIconType.DISTURBANCE_LEVEL_4_AUTOMATICALLY]: <NoiseIcon />,
  [EventIconType.DISTURBANCE_LEVEL_4_MANUALLY]: <NoiseIcon />,
  [EventIconType.DISTURBANCE_POTENTIAL_NOISE]: <NoiseIcon />,
  [EventIconType.SOUND_OF_INTEREST]: <NoiseIcon />,
  [EventIconType.OCCUPANCY_SCANNED_DEVICE_LIMIT_EXCEEDED]: (
    <HighCrowdRiskIcon style={{ color: semanticEmergency }} />
  ),
  [EventIconType.TAMPER]: <TamperIcon />,
  [EventIconType.TAMPER_MOUNTED]: <TamperIcon />,
  [EventIconType.TAMPER_REMOVED]: <TamperIcon />,
  [EventIconType.DEVICE_POWER_OFF]: <DeviceOffIcon />,
  [EventIconType.DEVICE_INSTALL]: <DeviceIcon />,
  [EventIconType.ALARM_MUTED_BUTTON_PRESS]: <DeviceIcon />,
  [EventIconType.ALARM_GRACE_PERIOD_EXPIRED]: (
    <MotionDetectedIcon style={{ color: semanticEmergency }} />
  ),
  [EventIconType.ROOM_TOO_WARM]: <HighTemperatureIcon />,
  [EventIconType.BEDROOM_TOO_WARM]: <HighTemperatureIcon />,
  [EventIconType.ALMOST_FREEZING]: <FreezingIcon />,
  [EventIconType.RISK_OF_MOULD]: <MouldIcon />,
  [EventIconType.RISK_OF_MOULD_CLEARED]: <MouldIcon />,
  [EventIconType.BATTERY_LOW]: <BatteryLowIcon />,
  [EventIconType.BATTERY_EMPTY]: <BatteryEmptyIcon />,
  [EventIconType.CHARGER_CONNECTED]: <DeviceIcon />,
  [EventIconType.CHARGER_DISCONNECTED]: <DeviceIcon />,
  [EventIconType.BATTERY_CHARGING_COMPLETE]: <DeviceIcon />,
  [EventIconType.WIFI_RECONFIGURED]: <DeviceOnlineIcon />,
  [EventIconType.GLASSBREAK]: (
    <GlassBreakIcon style={{ color: semanticEmergency }} />
  ),
  [EventIconType.USER_INVITED_ANOTHER_USER_TO_HOME]: <UserInviteIcon />,
  [EventIconType.USER_RECEIVED_INVITATION_TO_OTHER_HOME]: <UserInviteIcon />,
  [EventIconType.INVITATION_ACCEPTED_TO_HOME_MEMBERS_VIEW]: <UserInviteIcon />,
  [EventIconType.NON_EXISTING_USER_INVITED_TO_HOME]: <UserInviteIcon />,
  [EventIconType.DOG_BARK]: <DogBarkIcon />,
  [EventIconType.ALARM_ON_AUTOMATICALLY]: <AlarmOnIcon />,
  [EventIconType.RESPONSE_SERVICE_DISPATCH_ARRIVED]: <ResponsePartnerIcon />,
  [EventIconType.RESPONSE_SERVICE_DISPATCH_IN_PROGRESS]: (
    <ResponsePartnerIcon />
  ),
  [EventIconType.RESPONSE_SERVICE_DISPATCH_COMPLETED]: <ResponsePartnerIcon />,
  [EventIconType.RESPONSE_SERVICE_DISPATCH_CANCELLED_BY_USER]: (
    <ResponsePartnerIcon />
  ),
  [EventIconType.RESPONSE_SERVICE_DISPATCH_CANCELLED_BY_PROVIDER]: (
    <ResponsePartnerIcon />
  ),
  [EventIconType.RESPONSE_SERVICE_DISPATCH_CANCELLED_BY_MINUT_WHILE_IN_PROGRESS]:
    <ResponsePartnerIcon />,
  [EventIconType.RESPONSE_SERVICE_DISPATCH_CANCELLED_BY_MINUT_WHILE_REQUESTED]:
    <ResponsePartnerIcon />,
  [EventIconType.RESPONSE_SERVICE_DISPATCH_REQUESTED_AUTOMATICALLY]: (
    <ResponsePartnerIcon />
  ),
  [EventIconType.RESPONSE_SERVICE_DISPATCH_REQUESTED_MANUALLY]: (
    <ResponsePartnerIcon />
  ),
  [EventIconType.SMOKING_DETECTION_CALIBRATION_DONE]: (
    <SmokingDetectionIcon width={40} height={40} />
  ),
  [EventIconType.SMOKING_DETECTION_SMOKING_DETECTED]: (
    <SmokingDetectionIcon width={40} height={40} />
  ),
  [EventIconType.CALLCENTER_GUEST_CALL]: <CallAssistIcon width={40} />,
  [EventIconType.CALLCENTER_GUEST_CALL_COMPLETED]: (
    <CallAssistIcon width={40} />
  ),
  [EventIconType.CALLCENTER_GUEST_CALL_NO_ANSWER]: (
    <CallAssistIcon width={40} />
  ),
  [EventIconType.SENSOR_SWAP_SUCCEEDED]: <SensorSwapSuccessIcon />,
  [EventIconType.SENSOR_SWAP_FAILED]: <SensorSwapFailedIcon />,

  // Eureka
  [EventIconType.FIRE_SMOKE_ALARM]: (
    <FireAlarmIcon color={colorsLegacy.semanticEmergency} />
  ),
  [EventIconType.FIRE_SMOKE_CLEARED]: <FireAlarmIcon />,
  [EventIconType.CO_ALARM]: (
    <COAlarmIcon color={colorsLegacy.semanticEmergency} />
  ),
  [EventIconType.CO_CLEARED]: <COAlarmIcon />,
  [EventIconType.FIRE_CO_SELF_TEST_SUCCESS]: <OkLogIcon />,
  [EventIconType.FIRE_CO_SELF_TEST_FAIL]: <ErrorLogIcon />,

  // Events with undefined icons:
  [EventIconType.FIRST_MORNING]: undefined,
  [EventIconType.ALARM_DETECTION_TEST]: undefined,
  [EventIconType.INTRODUCTION_DAY_1]: undefined,
  [EventIconType.INTRODUCTION_DAY_2_DEVICE_INSTALLED]: undefined,
  [EventIconType.INTRODUCTION_DAY_2_NO_DEVICE]: undefined,
  [EventIconType.INTRODUCTION_DAY_4]: undefined,
  [EventIconType.INTRODUCTION_DAY_7]: undefined,
  [EventIconType.SMOKE_DETECTED]: undefined,
  [EventIconType.PIR_MOTION]: undefined,
  [EventIconType.ALARM_SILENCED]: undefined,
  [EventIconType.ALARM_OFF_AUTOMATICALLY]: undefined,
  [EventIconType.ALARM_NOT_AUTO_ON_ALL_DEVICES_OFFLINE]: undefined,
  [EventIconType.INVITATION_ACCEPTED_TO_HOME_INVITEE_VIEW]: undefined,
  [EventIconType.USER_ARRIVED_HOME]: undefined,
  [EventIconType.USER_LEFT_HOME]: undefined,
  [EventIconType.MEMBER_ARRIVED_HOME]: undefined,
  [EventIconType.MEMBER_LEFT_HOME]: undefined,
  [EventIconType.TURN_ON_ALARM_REMINDER]: undefined,
  [EventIconType.TURN_OFF_ALARM_REMINDER]: undefined,
  [EventIconType.LAST_MEMBER_LEFT]: undefined,
  [EventIconType.FIRST_MEMBER_ARRIVED]: undefined,
  [EventIconType.SMOKE_DETECTOR_TEST_REMINDER]: undefined,
  [EventIconType.WEBHOOK_PING]: undefined,
  [EventIconType.AIR_TOO_DRY]: undefined,
  [EventIconType.AIR_TOO_HUMID]: undefined,
  [EventIconType.DISTURBANCE_ENDED]: undefined,
  [EventIconType.DISTURBANCE_DISMISSED]: undefined,
  [EventIconType.DISTURBANCE_SNOOZED]: undefined,
  [EventIconType.OCCUPANCY_SCANNED_DEVICE_LIMIT_SUBCEEDED]: undefined,
}

function ResponsePartnerIcon() {
  return (
    <IconBox>
      <ResponseServiceIcon />
    </IconBox>
  )
}

const IconBox = styled.div`
  & > svg {
    width: 40px;
    height: 40px;
    fill: currentColor;
  }
`
