import { paths } from "@minuthq/minut-api-types/schema"
import { useMutation } from "@tanstack/react-query"
import { AxiosError, AxiosResponse } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

type TApplicationsFeedbackPostRequest =
  paths["/applications/feedback"]["post"]["requestBody"]["content"]["application/json"]
type TSmokeFeedbackPostRequest = TApplicationsFeedbackPostRequest & {
  event_type: "smoking_detection_smoking_detected"
}

type TApplicationsFeedbackPostResponse =
  paths["/applications/feedback"]["post"]["responses"]["201"]["content"]["application/json"]
type TSmokeFeedbackResponse = TApplicationsFeedbackPostResponse & {
  event_type: "smoking_detection_smoking_detected"
}

export function usePostSmokeFeedbackLegacy() {
  async function postCallAssistFeeback(data: TSmokeFeedbackPostRequest) {
    const result = await minutApiHttpClient.post<
      TSmokeFeedbackPostRequest,
      AxiosResponse<TSmokeFeedbackResponse>
    >(`${API_DEFAULT}/applications/feedback`, data)
    return result.data
  }

  return useMutation<
    TSmokeFeedbackResponse,
    AxiosError,
    TSmokeFeedbackPostRequest
  >({
    mutationFn: postCallAssistFeeback,
  })
}

type THomeEventFeedbackPostRequest =
  paths["/homes/{home_id}/events/{home_event_id}/feedback"]["post"]["requestBody"]["content"]["application/json"]

type THomeEventFeedbackPostResponse =
  paths["/homes/{home_id}/events/{home_event_id}/feedback"]["post"]["responses"]["201"]["content"]["application/json"]

type TPostHomeEventFeedbackPathParams = {
  home_id: string
  home_event_id: string
}

export function usePostHomeEventFeedback() {
  async function postCallAssistFeeback({
    home_id,
    home_event_id,
    ...data
  }: THomeEventFeedbackPostRequest & TPostHomeEventFeedbackPathParams) {
    const result = await minutApiHttpClient.post<
      THomeEventFeedbackPostRequest,
      AxiosResponse<THomeEventFeedbackPostResponse>
    >(`${API_DEFAULT}/homes/${home_id}/events/${home_event_id}/feedback`, data)
    return result.data
  }

  return useMutation<
    THomeEventFeedbackPostResponse,
    AxiosError,
    THomeEventFeedbackPostRequest & TPostHomeEventFeedbackPathParams
  >({
    mutationFn: postCallAssistFeeback,
  })
}
