import { EmptyStateWithImage } from "src/components/EmptyState/EmptyStateWithImage"
import { useTranslate } from "src/i18n/useTranslate"

import HomeEventsEmptyStateImage from "./images/home-events-empty-state.svg"
import { AppStoreLogos } from "./AppStoreLogos"

export function NoEvents() {
  const { t, langKeys } = useTranslate()

  return (
    <EmptyStateWithImage
      Image={HomeEventsEmptyStateImage}
      title={t(langKeys.events_empty_state_title)}
      description={t(langKeys.events_empty_state_description)}
      content={<AppStoreLogos />}
    />
  )
}
