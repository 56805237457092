import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  TFetchHomeLogEventsQueryParams,
  TFetchHomeLogEventsResponse,
} from "src/data/events/types/homeEventTypes"
import { homeKeys } from "src/data/homes/queries/homeQueryCache"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { MinutQueryFilter, useMinutQuery } from "src/hooks/minutQuery"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchHomeEvents<ReturnData = TFetchHomeLogEventsResponse>({
  homeId,
  params,
  options,
}: {
  homeId: string
  params?: TFetchHomeLogEventsQueryParams
  options?: UseQueryOptions<
    TFetchHomeLogEventsResponse,
    AxiosError,
    ReturnData,
    ReturnType<typeof homeKeys.homeEvents>
  >
}) {
  const { orgId } = useOrganization()
  async function fetchHomeEvents() {
    const response = await minutApiHttpClient.get<TFetchHomeLogEventsResponse>(
      `${API_DEFAULT}/homes/${homeId}/events`,
      { params: params }
    )
    return response.data
  }

  return useQuery({
    queryKey: homeKeys.homeEvents(orgId, homeId, params),
    queryFn: fetchHomeEvents,
    ...options,
  })
}

export function useFetchHomeEventsCount({ homeId }: { homeId: string }) {
  return useFetchHomeEvents({
    homeId,
    params: { limit: 1 },
    options: { select: (data) => data.paging.total_count },
  })
}

export function useFetchHomeEventsGlobal({
  filters,
}: {
  filters: MinutQueryFilter<"/organizations/{organization_id}/events">
}) {
  const { orgId: organization_id } = useOrganization()
  return useMinutQuery<"/organizations/{organization_id}/events">({
    queryKey: homeKeys.homeEventsGlobal(organization_id, filters),
    queryPath: `/organizations/${organization_id}/events`,
    filters,
    options: { enabled: !!organization_id },
  })
}

export function useFetchHomeEventsGlobalCount() {
  const { orgId: organization_id } = useOrganization()

  return useMinutQuery<
    "/organizations/{organization_id}/events",
    "400",
    number | undefined
  >({
    queryKey: homeKeys.homeEventsGlobal(organization_id, { limit: 1 }),
    queryPath: `/organizations/${organization_id}/events`,
    filters: { limit: 1 },
    options: {
      enabled: !!organization_id,
      select: (data) => data.paging.total_count,
    },
  })
}
